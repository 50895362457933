<script setup>
import Layout from '../../layouts/main.vue'
import PageHeader from '@/components/page-header'
import store from '../../../state/store';
import { callApi } from '../../../msal/msalApi';
import { reactive, onMounted, computed,onBeforeMount } from 'vue';
import Swal from 'sweetalert2';

import { useRoute } from 'vue-router'

const route = useRoute()

let data = reactive({
    firstName: '',
    lastName: '',
    email: '',
    Franchise_ID: '',
    selectLocation: '',
    selectRol: '',
    practiceGroupArray: [],
    locationArray: [],
    rolesArray: [],

    practiceRol: '2',

    remoteAccess: false,


    practitioner: false,
    OfficeAdmin: false,
    CoachManager: false,
    Coach: false,
    locationID: '',

    addLocation: [],
})

async function getPracticeGroup() {
    console.log("franchise:"+data.Franchise_ID,route.query)

    let url = `${process.env.VUE_APP_WLT_API}Associate/PracticeGroupMembersPaged?email=${store.getters.getEmail}&sortByList[0].Name=FranchiseName&sortByList[0].Direction=Asc&sortByList[1].Name=LastName&sortByList[1].Direction=Desc&franchiseLocationStatusFilters=1`;

    if (data.selectLocation && data.Franchise_ID) {
        url += `&franchiseLocationIdFilters[0]=${data.selectLocation}&franchiseLocationIdFilters[1]=${data.Franchise_ID}`
    }
    if (data.Franchise_ID) {
        url += `&franchiseIdFilters[0]=${data.Franchise_ID}`;
    }

    if (data.selectLocation) {
        url += `&franchiseLocationIdFilters[0]=${data.selectLocation}`;
    }

    let response = await callApi(url, store.getters.getToken)

    data.practiceGroupArray = response.franchiseSelections
    data.locationArray = response.franchiseLocations
    data.rolesArray = response.practiceRoles

    if (data.practiceGroupArray.length === 1) {
        data.Franchise_ID = data.practiceGroupArray[0].franchise_ID

    }
   

}


let locationChanges = reactive({})


function handleCheckboxChange(event) {
    if (event.target.type === 'checkbox') {
        const checkbox = event.target;
        let locationId = checkbox.closest('.check-container').dataset.locationId;
        let locationIndex = data.locationArray.findIndex(location => location.franchiseLocation_ID === locationId);

        // Almacenar temporalmente los cambios en el objeto locationChanges
        locationChanges[locationId] = {
            isPractitioner: data.practitioner,
            isCoachManager: data.CoachManager,
            isOfficeManager: data.OfficeAdmin,
            isCoach: data.Coach,
            shouldAdd: checkbox.checked,
            locationIndex: locationIndex
        };
    }
    console.log(locationChanges)
}

data.locationArray.forEach(location => {
    location.role = '';
});



function handleSaveButtonClick() {
    // Aplicar los cambios al arreglo data.addLocation y enviarlos al servidor
    for (const locationId in locationChanges) {
        const locationChange = locationChanges[locationId];
        const existingLocationIndex = data.addLocation.findIndex(loc => loc.franchiseLocation_ID === locationId);

        if (existingLocationIndex > -1) {
            // Actualizar las propiedades correspondientes del objeto existente
            const existingLocation = data.addLocation[existingLocationIndex];
            existingLocation.isPractitioner = locationChange.isPractitioner;
            existingLocation.isCoachManager = locationChange.isCoachManager;
            existingLocation.isOfficeManager = locationChange.isOfficeManager;
            existingLocation.isCoach = locationChange.isCoach;

            // Asignar el valor del rol a la propiedad correspondiente de cada objeto en locationArray
            const locationIndex = locationChange.locationIndex;
            const location = data.locationArray[locationIndex];
            location.role = '';
            if (existingLocation.isPractitioner) {
                location.role += 'Practitioner, ';
            }
            if (existingLocation.isCoachManager) {
                location.role += 'Coach Manager, ';
            }
            if (existingLocation.isOfficeManager) {
                location.role += 'Office Manager, ';
            }
            if (existingLocation.isCoach) {
                location.role += 'Coach, ';
            }
            location.role = location.role.slice(0, -2); // Eliminar la última coma y el espacio en blanco

            console.log("actualiza AL ARREGLO" + existingLocation.franchiseLocation_ID);
            console.log(data.addLocation)


            // Eliminar el objeto si el checkbox se deselecciona
            if (!locationChange.shouldAdd) {
                data.addLocation.splice(existingLocationIndex, 1);
            }
        } else if (locationChange.shouldAdd) {
            // Agregar un nuevo objeto al arreglo
            const obj = {
                franchise_ID: data.Franchise_ID,
                franchiseLocation_ID: locationId,
                isPractitioner: locationChange.isPractitioner,
                isCoachManager: locationChange.isCoachManager,
                isOfficeManager: locationChange.isOfficeManager,
                isCoach: locationChange.isCoach,
            };

            // Asignar el valor del rol a la propiedad correspondiente de cada objeto en locationArray
            const locationIndex = locationChange.locationIndex;
            const location = data.locationArray[locationIndex];
            location.role = '';
            if (obj.isPractitioner) {
                location.role += 'Practitioner, ';
            }
            if (obj.isCoachManager) {
                location.role += 'Coach Manager, ';
            }
            if (obj.isOfficeManager) {
                location.role += 'Office Manager, ';
            }
            if (obj.isCoach) {
                location.role += 'Coach, ';
            }
            location.role = location.role.slice(0, -2); // Eliminar la última coma y el espacio en blanco

            data.addLocation.push(obj)
            console.log("AÑADIDO AL ARREGLO" + obj.franchiseLocation_ID)
            console.log(data.addLocation)

            data.Coach = false
            data.CoachManager = false
            data.OfficeAdmin = false
            data.practitioner = false

        }
    }

    // Limpiar el objeto locationChanges para la siguiente operación
    locationChanges = {};

}


function removeLocation(locationId) {
    const index = data.addLocation.findIndex(location => location.franchiseLocation_ID === locationId);
    locationChanges = {}

    if (index !== -1) {
        // Colocar los roles en false
        data.addLocation[index].isPractitioner = false;
        data.addLocation[index].isCoachManager = false;
        data.addLocation[index].isOfficeManager = false;
        data.addLocation[index].isCoach = false;


        // Desmarcar los checkboxes correspondientes
        const checkboxes = document.querySelectorAll(`input[type=checkbox][data-checkbox-id="${locationId}"]`);
        checkboxes.forEach(checkbox => {
            checkbox.checked = false;
        });

        // Eliminar la ubicación del arreglo
        data.addLocation.splice(index, 1);

        console.log(data.addLocation);
        console.log(checkboxes)
        // Establecer la propiedad 'role' en una cadena vacía en locationArray
        const locationIndex = data.locationArray.findIndex(location => location.franchiseLocation_ID === locationId);
        if (locationIndex !== -1) {
            data.locationArray[locationIndex].role = '';
        }
    }
}



function showRolesAlert() {
    const msg = [];
    const roles = [];

    data.locationArray.forEach(rol => {
        if (!roles.includes(rol.role)) {
            roles.push(rol.role);
            msg.push({ rolname: rol.role });
        }
    });

    return msg[0].rolname
}


function getErrorText(text) {
    const regex = /FOREIGN KEY constraint "(.*?)"/;
    const match = text.match(regex);

    if (match && match.length === 2) {
        const constraintName = match[1];
        console.log(constraintName)
        return Swal.fire('A user with this email already exists', '', 'error')
    }

}


async function sendNewAssociate() {
    const { firstName, lastName, email, Franchise_ID, selectLocation, selectRol, practiceRol } = data
    console.log(firstName, lastName, email, Franchise_ID, selectLocation, selectRol, practiceRol)

    let franchiseLocations = data.addLocation.map(location => ({
        "franchise_ID": location.franchise_ID,
        "franchiseLocation_ID": location.franchiseLocation_ID,
        "isPractitioner": location.isPractitioner,
        "isCoachManager": location.isCoachManager,
        "isOfficeManager": location.isOfficeManager,
        "isCoach": location.isCoach,
    }));
    try {
        const url = `${process.env.VUE_APP_WLT_API}Associate/MemberAdd`;

        const response = await fetch(url, {
            method: "POST",
            body: JSON.stringify(
                {

                    "franchise_ID": Franchise_ID,
                    "firstName": firstName,
                    "lastName": lastName,
                    "email": email,
                    "remote": data.remoteAccess,
                    "practiceGroupRole": parseInt(practiceRol),
                    "franchiseLocations": franchiseLocations
                }
            ),
            headers: {
                Authorization: `Bearer ${store.getters.getToken}`,
                "Content-Type": "application/json"
            }
        });

        if (response.status === 400 || response.status === 500 || response.status === 401 || response.status === 405) {
            const errorText = await response.text();
            return getErrorText(errorText)
        } else {
            Swal.fire(`
                <strong>Role/Roles:${showRolesAlert()}</strong> <br> have been added to user <strong>${email}</strong>.`, '', 'success')


            data.Coach = false
            data.CoachManager = false
            data.OfficeAdmin = false
            data.practitioner = false
            data.firstName = ''
            data.lastName = ''
            data.email = ''
            data.selectLocation = ''
            data.Franchise_ID = ''

        }
        console.log(response)
        console.log(franchiseLocations)
    } catch (error) {
        console.log(error.message);

        return Swal.fire('Error!', '', 'error')
    }
    data.Coach = false
    data.CoachManager = false
    data.OfficeAdmin = false
    data.practitioner = false
    data.addLocation = []
    franchiseLocations = []
    //getPracticeGroup()

}


function getLocationRole(index) {
    const location = data.locationArray[index];
    const locationObject = data.addLocation.find(loc => loc.franchiseLocation_ID === location.franchiseLocation_ID);

    if (locationObject) {
        const role = [];

        if (locationObject.isPractitioner) {
            role.push('Practitioner');
        }
        if (locationObject.isCoachManager) {
            role.push('Coach Manager');
        }
        if (locationObject.isOfficeManager) {
            role.push('Office Manager');
        }
        if (locationObject.isCoach) {
            role.push('Coach');
        }

        return role.join(', ');
    } else {
        return '';
    }
}



// Función para asignar valores de roles a las directivas v-model
function assignRoleValues(franchiseLocationId) {
    // Buscamos el objeto en el array que tenga el franchiseLocationId buscado
    const locationObj = data.addLocation.find(obj => obj.franchiseLocation_ID === franchiseLocationId);

    // Si lo encontramos, asignamos los valores correspondientes a las directivas v-model
    if (locationObj) {
        data.practitioner = locationObj.isPractitioner;
        data.OfficeAdmin = locationObj.isOfficeManager;
        data.CoachManager = locationObj.isCoachManager;
        data.Coach = locationObj.isCoach;
    } else {
        // Desmarcar los checkboxes correspondientes
        const checkboxes = document.querySelectorAll(`input[type=checkbox][data-checkbox-id="${franchiseLocationId}"]`);
        checkboxes.forEach(checkbox => {
            checkbox.checked = false;
        });
        data.practitioner = false;
        data.OfficeAdmin = false;
        data.CoachManager = false;
        data.Coach = false;
    }
    console.log(locationObj)
    console.log(franchiseLocationId)

}


computed(getLocationRole)

onBeforeMount(() => {
    if (route.query.franchiseID) {
        data.Franchise_ID = route.query.franchiseID

    }
})
onMounted(getPracticeGroup, document.addEventListener('change', handleCheckboxChange)
)


</script>
    
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <h2>New User</h2>

        
    <div class="container">
        <div class="row d-flex justify-content-center">
            <div class="col-sm-12 col-md-8 col-lg-5">
                <form action="">
                    <select v-if="data.practiceGroupArray.length > 1" class="form-select form-select-lg mb-3"
                        aria-label=".form-select-lg example" v-model="data.Franchise_ID" @change="getPracticeGroup">
                        <option selected value="">Practice Group Name</option>
                        <option selected :value="franchise.franchise_ID"
                            v-for="(franchise, index) in data.practiceGroupArray" :key="index">
                            {{ franchise.franchiseName }}
                        </option>
                    </select>
                    <select v-if="data.practiceGroupArray.length === 1" class="form-select form-select-lg mb-3"
                        aria-label=".form-select-lg example" v-model="data.Franchise_ID" @change="getPracticeGroup">
                        <option :value="data.Franchise_ID">
                            {{ data.practiceGroupArray[0].franchiseName }}
                        </option>
                    </select>


                    <div class="row">
                        <div class="col">
                            <div class="mb-3">
                                <label for="exampleFormControlInput1" class="form-label">First name</label>
                                <input type="text" class="form-control" placeholder="" v-model="data.firstName">
                            </div>
                        </div>
                        <div class="col">
                            <div class="mb-3">
                                <label for="exampleFormControlInput1" class="form-label">Last name</label>
                                <input type="text" class="form-control" placeholder="" v-model="data.lastName">
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="exampleFormControlInput1" class="form-label">Email</label>
                            <input type="email" class="form-control" placeholder="" v-model="data.email">
                        </div>


                        <div class="col">
                            <div class="col">
                                <select @click="getPracticeGroup" class="form-select" v-model="data.practiceRol">
                                    <option value="0" :selected="data.practiceRol === '0'">Practice Group Owner</option>
                                    <option value="1" :selected="data.practiceRol === '1'">Practice Group Admin</option>
                                    <option value="2" :selected="data.practiceRol === '2'">Practice Group Member</option>
                                </select>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
            <table class=" col-sm-12 col-md-8 col-lg-5 table table-dark table-responsive mt-5">
                <thead>
                    <tr>
                        <th scope="col">Location</th>
                        <th scope="col">Roles</th>
                        <th>

                        </th>
                    </tr>
                </thead>
                <tbody v-if="data.Franchise_ID">
                    <tr v-for="(item, index) in data.locationArray" :key="index">
                        <th>
                            <div class="check-container" :data-location-id="item.franchiseLocation_ID">
                                <div class="form-check mb-3 h5">
                                    <label class="form-check-label fw-bold text-white">{{ item.locationName }}</label>
                                </div>
                            </div>
                        </th>

                        <th v-if="item.role">

                            {{ item.role }}
                        </th>
                        <th v-else>

                        </th>
                        <th>
                            <ul class="list-group list-group-flush">
                                <li class="list-item fw-bold">
                                    <button type="button" class="btn" data-bs-toggle="modal" data-bs-backdrop="static"
                                        @click="assignRoleValues(item.franchiseLocation_ID)"
                                        :data-bs-target="'#editRoleModal-' + index">
                                        <i class='bx bx-edit h3  text-white'></i>
                                    </button>
                                    <div class="modal fade" :id="'editRoleModal-' + index" tabindex="-1"
                                        data-bs-backdrop="static" aria-labelledby="editRoleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog modal-dialog-centered">
                                            <div class="modal-content">
                                                <div class="modal-body">
                                                    <div class="check-container"
                                                        :data-location-id="item.franchiseLocation_ID">
                                                        <div class="form-check mb-3 h5">
                                                            <input class="form-check-input" type="checkbox"
                                                                :data-checkbox-id="item.franchiseLocation_ID"
                                                                v-bind:checked="locationChanges.isPractitioner ? true : false"
                                                                @change="data.practitioner = !data.practitioner">
                                                            <label
                                                                class="form-check-label fw-bold text-dark">Practitioner</label>
                                                        </div>
                                                    </div>

                                                    <div class="check-container"
                                                        :data-location-id="item.franchiseLocation_ID">
                                                        <div class="form-check mb-3 h5">
                                                            <input class="form-check-input" type="checkbox"
                                                                :data-checkbox-id="item.franchiseLocation_ID"
                                                                v-bind:checked="locationChanges.isOfficeManager ? true : false"
                                                                @change="data.OfficeAdmin = !data.OfficeAdmin">

                                                            <label
                                                                class="form-check-label fw-bold text-dark">OfficeAdmin</label>
                                                        </div>
                                                    </div>
                                                    <div class="check-container"
                                                        :data-location-id="item.franchiseLocation_ID">
                                                        <div class="form-check mb-3 h5">
                                                            <input class="form-check-input" type="checkbox"
                                                                :data-checkbox-id="item.franchiseLocation_ID"
                                                                v-bind:checked="locationChanges.isCoachManager ? true : false"
                                                                @change="data.CoachManager = !data.CoachManager">

                                                            <label class="form-check-label fw-bold text-dark">Coach
                                                                Manager</label>
                                                        </div>
                                                    </div>
                                                    <div class="check-container"
                                                        :data-location-id="item.franchiseLocation_ID">
                                                        <div class="form-check mb-3 h5">
                                                            <input class="form-check-input" type="checkbox"
                                                                :data-checkbox-id="item.franchiseLocation_ID"
                                                                v-bind:checked="locationChanges.isCoach ? true : false"
                                                                @change="data.Coach = !data.Coach">

                                                            <label class="form-check-label fw-bold text-dark">Coach</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                                                        @click="handleSaveButtonClick">Add</button>
                                                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                                                        :data-location-id="item.franchiseLocation_ID"
                                                        @click="removeLocation(item.franchiseLocation_ID)">Cancel</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </th>
                    </tr>
                </tbody>
            </table>


            <div class="col-4">
                <div class="d-grid gap-2 mt-3">
                    <button class="btn btn-primary" type="button" @click.prevent="sendNewAssociate"
                        :disabled="data.practiceRol === '2' && data.addLocation.length === 0">Add</button>
                </div>
            </div>
        </div>
    </div>
    </Layout>

</template>

<style>
.list-item {
    list-style: none;
}
</style>